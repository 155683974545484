import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import { productData } from "../data/productData";
import { Typography } from "@mui/material";
import { Button } from "react-bootstrap/";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ScaleIcon from "@mui/icons-material/Scale";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ height: "90vh" }}
            src="images/packedPalmss169.jpg"
            alt="First slide"
          />
          <Card.ImgOverlay
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            className="d-flex justify-content-center align-items-center rounded-0"
          >
            <Container className="row">
              <div className="col-8" style={{ paddingLeft: "2rem" }}>
                <Card.Title style={{ color: "white" }}>
                  <Typography variant="h3" align="left" mb={2}>
                    Palm Oil
                  </Typography>
                  {/* <Typography variant="subtitle1" align="left">
                    Palm Oil
                  </Typography> */}
                  {/* <div className="mt-2 d-md-flex flex-start">
                    <Button className="btn-sm btn-danger d-flex align-items-center">
                      <Typography variant="caption2">Learn More</Typography>
                      <ArrowRightIcon />
                    </Button>
                  </div> */}
                </Card.Title>
              </div>
            </Container>
          </Card.ImgOverlay>
          {/* <Carousel.Caption>
            <div className="d-flex justify-content-center">
              <h2 className="p-2 mb-0" style={{ backgroundColor: "black" }}>
                Edible Oil Processing
              </h2>
            </div>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ height: "90vh" }}
            src="images/cement3ss169.jpg"
            alt="Second slide"
          />
          <Card.ImgOverlay
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            className="d-flex justify-content-center align-items-center rounded-0"
          >
            <Container className="row">
              <div className="col-8" style={{ paddingLeft: "2rem" }}>
                <Card.Title style={{ color: "white" }}>
                  <Typography variant="h3" align="left" mb={2}>
                    Cement
                  </Typography>
                  {/* <Typography variant="subtitle1" align="left">
                    Cement
                  </Typography> */}
                  {/* <div className="mt-2 d-md-flex flex-start">
                    <Button className="btn-sm btn-danger d-flex align-items-center">
                      <Typography variant="caption2">Learn More</Typography>
                      <ArrowRightIcon />
                    </Button>
                  </div> */}
                </Card.Title>
              </div>
            </Container>
          </Card.ImgOverlay>
          {/* 
          <Carousel.Caption>
            <h2>Food</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            style={{ height: "90vh" }}
            src="images/fertilizer1ss169.jpg"
            alt="Third slide"
          />
          <Card.ImgOverlay
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            className="d-flex justify-content-center align-items-center rounded-0"
          >
            <Container className="row">
              <div className="col-8" style={{ paddingLeft: "2rem" }}>
                <Card.Title style={{ color: "white" }}>
                  <Typography variant="h3" align="left" mb={2}>
                    Fertilizer
                  </Typography>
                  {/* <Typography variant="subtitle1" align="left">
                    Sugar
                  </Typography> */}
                  {/* <div className="mt-2 d-md-flex flex-start">
                    <Button className="btn-sm btn-danger d-flex align-items-center">
                      <Typography variant="caption2">Learn More</Typography>
                      <ArrowRightIcon />
                    </Button>
                  </div> */}
                </Card.Title>
              </div>
            </Container>
          </Card.ImgOverlay>
          {/* <Carousel.Caption>
            <h2>Industrial</h2>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      {/* <section className="m-2 p-2">
        <Container>
          <Row className="text-danger" style={{ minHeight: "30vh" }}>
            <Col
              xs={6}
              lg={3}
              className=" d-flex justify-content-start align-items-center"
            >
              <AttachMoneyIcon sx={{ fontSize: "100px" }} />
              <h5 className="text-start m-0">USD3 billion Annual Turnover</h5>
            </Col>
            <Col
              xs={6}
              lg={3}
              className=" d-flex justify-content-start align-items-center"
            >
              <TrendingUpIcon sx={{ fontSize: "100px" }} />
              <h5 className="text-start m-0">30+ Years Experience</h5>
            </Col>
            <Col
              xs={6}
              lg={3}
              className=" d-flex justify-content-start align-items-center"
            >
              <ScaleIcon sx={{ fontSize: "100px" }} />
              <h5 className="text-start m-0">
                10M Metric Tons Traded Per Year
              </h5>
            </Col>
            <Col
              xs={6}
              lg={3}
              className=" d-flex justify-content-start align-items-center"
            >
              <PeopleAltIcon sx={{ fontSize: "100px" }} />
              <h5 className="text-start m-0">60+ People</h5>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="p-2">
        <Container className="p-3">
          <Row className="">
            <h2>Products</h2>
          </Row>
        </Container>
        <Container>
          <div
            // style={{
            //   position: "relative",
            //   bottom: "6rem",
            //   overflow: "hidden",
            //   zIndex: "100",
            // }}
            className="d-flex flex-wrap justify-content-center"
          >
            {productData.map((product, index) => (
              <Col xs={5} md={4} key={index} className="p-2">
                <Card
                  className="rounded-0 border-0 cursor-pointer h-100"
                  style={{ boxShadow: "0px 2px 11px -4px #000000" }}
                  onClick={() => navigate(`/products/${product.route}`)}
                >
                  <Card.Img
                    className="rounded-0 img-zoom product-card-img w-100 h-100"
                    variant="top"
                    src={
                      product.picture_url?.length > 0
                        ? product.picture_url
                        : `https://picsum.photos/300/200?random=${index}`
                    }
                  />
                  <Card.ImgOverlay
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                    }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <p className="m-0" style={{ color: "white" }}>
                      {product.navLinkName}
                    </p>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            ))}
          </div>
        </Container>
      </section>
      {/* <section
        style={{
          backgroundImage: "url(https://picsum.photos/1920/1080?random=6)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: "60vh",
          position: "relative",
          padding: "3rem",
        }}
      >
        <Container className="h-100">
          <Row className="h-100">
            <Card.ImgOverlay
              style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
              className="d-flex justify-content-center align-items-center rounded-0"
            >
              <Container className="row justify-content-center">
                <div
                  className="col-8 d-flex justify-content-center"
                  style={{ paddingLeft: "2rem" }}
                >
                  <Card.Title style={{ color: "white" }}>
                    <Typography variant="h4" align="left" mb={2}>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                    <Typography variant="subtitle1" align="left">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Culpa aliquam impedit quibusdam soluta mollitia dolorem
                      recusandae ab iste cumque. Similique explicabo saepe
                      perferendis expedita quaerat magni. Harum dolores
                      assumenda maiores.
                    </Typography>
                    <div className="mt-2 d-md-flex flex-start">
                      <Button className="btn-sm btn-danger d-flex align-items-center">
                        <Typography variant="caption2">Learn More</Typography>
                        <ArrowRightIcon />
                      </Button>
                    </div>
                  </Card.Title>
                </div>
              </Container>
            </Card.ImgOverlay>
          </Row>
        </Container>
      </section> */}
      {/* <section style={{ height: "80vh", padding: "2rem" }}>
        <Container style={{ height: "100%" }} className="p-1">
          <Row style={{ height: "100%" }}>
            <Col md={7} style={{ height: "100%" }} className="p-1">
              <div
                className="border border-danger"
                style={{ height: "100%" }}
              ></div>
            </Col>
            <Col md={5} style={{ height: "100%" }}>
              <Row className="h-50 p-1">
                <div
                  className="border border-danger"
                  style={{ height: "100%" }}
                ></div>
              </Row>
              <Row className="h-50 p-1">
                <div
                  className="border border-danger"
                  style={{ height: "100%" }}
                ></div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Home;
