import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

const Map = ({ location, zoomLevel }) => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.817670284208!2d103.84708477436584!3d1.283249961787558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xab8e653576c0851%3A0x19e653e7621a161c!2sAastar%20Trading%20Pte%20Ltd!5e0!3m2!1sen!2ssg!4v1686536821111!5m2!1sen!2ssg" 
      width="80%" 
      height="300" 
      style={{border: "0"}} 
      allowFullScreen="" 
      loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade">
    </iframe>
  );
};

export default Map;
