export const productData = [
  {
    navLinkName: "Oils & Fats",
    productCategory: "Oil and fats",
    route: "oil-and-fats",
    picture_url: "/images/oilsAndFats1ss.jpg",
    subCategory: [
      {
        name: "Palm & Lauric",
        products: [
          // { name: "CPO" },
          // { name: "RBD PALM OIL" },
          // { name: "RBD PALM OLEIN" },
          // { name: "RBD PALM STEARIN" },
          // { name: "PFAD" },
          // { name: "CPKO" },
          // { name: "RBD PKO" },
          // { name: "PKFAD" },
        ],
        description: [
          "Palm oil is one of the most versatile products in the world. It is being used for food applications such as cooking, margarines, spreads, confectionary fats, ice cream, emulsifiers, and Vanaspati. Palm Oil and derivatives known as fractions of the oil are used in the manufacture of pre-packaged food, cosmetics, cleaning products, hair care, soaps and personal care items. Palm Wax is used in the manufacture of candles. It is also used to manufacture bio fuel for motor vehicles, shipping and aircraft fuel and one of its by-products is glycerine.",
          "Palm kernel oil, which is produced from crushing of palm kernels, is used in the production of food such as margarine, shortening, cocoa butter substitute, etc. It is also used to produce ice creams due to the high melting point. It is also used to produce chocolate, mayonnaises, confectionaries, etc",
          "Aastar is one of the leading traders of palm and laurics from Indonesia. We have footprint in India, China, Europe, USA, Japan, Korea, Pakistan, Bangladesh, Philippines, Africa and more increasing.",
        ],
        pictures: [
          "oilsAndFats2ss",
          "oilsAndFats3ss",
          "oilsAndFats4ss",
          // "rbdpalmoilNew2",
          // "bulkNew5",
          // "bulkNew11",
          // "bulkNew3",
          // "bulkNew13",
          // "bulkNew7",
          // "bulkNew16",
          // "bulkNew9",
          // "bulkNew10",
          // "bulkNew12",
        ],
      },
      // {
      //   name: "Specialty Fats",
      //   products: [],
      //   description: "",
      // },
    ],
  },
  {
    navLinkName: "Packed Products",
    productCategory: "Packed Products",
    route: "packed",
    picture_url: "/images/mixed.jpg",
    subCategory: [
      {
        name: "Packed Products",
        products: [
          {
            name: "RBD PALM OLEIN",
            picture_url: "/images/packedOlein1ss43.jpg",
          },
          { name: "VEGETABLE GHEE", picture_url: "/images/ghee1ss43.jpg" },
          { name: "RBD PALM OIL", picture_url: "/images/packedPalmss43.jpg" },
          { name: "RBD PALM STEARIN", picture_url: "/images/stearin1ss43.jpg" },
          { name: "SHORTENING", picture_url: "/images/shortening1ss43.jpg" },
          { name: "FRYING FAT", picture_url: "/images/fryingFat1ss43.jpg" },
          // {
          //   name: "BUTTER OIL SUBSTITUTE",
          //   picture_url: "/images/butteroilsubNew4.jpg",
          // },
          // { name: "MILK FAT REPLACER", picture_url: "/images/milkfatNew2.jpg" },
          { name: "SUGAR", picture_url: "/images/sugar1ss43.jpg" },
          { name: "RICE", picture_url: "/images/rice1ss43.jpg" },
        ],
        description: [],
      },

      // { name: "Rice & Sugar", products: [{ name: "RICE" }, { name: "SUGAR" }] },
    ],
  },
  {
    navLinkName: "Biofuel",
    productCategory: "Biofuel",
    description: [
      "All our products (POME, PME & UCO) are all ISCC certified whereas POME is also INS certified. Aastar Trading offers an integrated value chain that links origination, logistical arrangement, storage and transportation to end users that we have been actively exporting to in Europe and Asia. With exclusive storage tanks located in Sumatra and across Kalimantan, and a well-connected network of logistics within Indonesia, Aastar Trading upholds a high reputation for reliability and quality in the industry. These assets are strategically located in our private jetties such that we are well-poised to manage our export programmes efficiently.",
    ],
    route: "biofuel",
    picture_url: "/images/biodieselHERO.jpg",
    subCategory: [
      {
        name: "Palm Oil Mill Effluent (POME)",
        pictures: [
          // "pome32",
          "pome(1)32",
          // "pome(2)32",
          "pome(3)32",
          // "pome(4)32",
          // "pome(5)32",
          // "pome(6)32",
          // "pome(7)32",
          "pome(8)32",
          "pome(10)32",
          "pome(12)32",
          "pome(13)32",
          "pome(14)32",
        ],
        videos: [
          {
            src: "/videos/pome-video.mp4",
            desc: "Worker working on POME field",
            poster: "/images/pome-video-poster.jpg",
          },
        ],
        description: [
          "Aastar Trading has POME collection plants from our own CPO mills and has developed long-term partnerships with local millers to consolidate the cargoes in our storage tanks. With treating plants available within the sites, Aastar Trading offers one of the best qualities available in the market.",
        ],
      },
      {
        name: "Used Cooking Oil (UCO)",
        description: [
          "Aastar Trading has storage tank facilities in Jakarta to collect UCO in which we have been exporting through containers.",
        ],
        products: [],
        pictures: [],
      },
      {
        name: "Palm Kernel Shell/Expeller",
        description: [
          "Palm kernel shell is the hard endocarp of the palm fruit, which is separated from the palm nut during processing. Palm kernel shell is a renewable and biodegradable energy source that can be used for power generation and other industrial applications. Palm kernel expeller is a by-product of the palm kernel crushing process. It is a high-protein, high-fiber material that can be used as a livestock feed or as a soil amendment. Palm kernel expeller is a valuable source of nutrition for livestock and can also be used to improve the quality of soil.",
        ],
        products: [],
        pictures: [],
      },
      {
        name: "Palm Methyl Ester (PME)",
        products: [],
        description: [
          "Our biofuel plant is located next to our palm oil refinery. Therefore, we are able to manufacture PME directly from the RBD PALM OIL / RBD PALM STEARIN that we do not require from the production of RBD PALM OLEIN. Depending on market movements, we are also able to efficiently change our production process to capture those opportunities. Aastar is expected to have capabilities of 1.5million tonne/annum in West & East Kalimantan.",
        ],
      },
      // {
      //   name: "Biofuel",
      //   description:
      //     "All our products (POME, PME & UCO) are all ISCC certified whereas POME is also INS certified. Aastar Trading offers an integrated value chain that links origination, logistical arrangement, storage and transportation to end users that we have been actively exporting to in Europe and Asia. With exclusive storage tanks located in Sumatra and across Kalimantan, and a well-connected network of logistics within Indonesia, Aastar Trading upholds a high reputation for reliability and quality in the industry. These assets are strategically located in our private jetties such that we are well-poised to manage our export programmes efficiently.",
      //   products: [
      //     { name: "Palm Oil Mill Effluent (POME)" },
      //     { name: "Used Cooking Oil (UCO)" },
      //     { name: "Palm Kernel Shell/Expeller" },
      //     { name: "Palm Methyl Ester (PME)" },
      //   ],
      //   pictures: [
      //     "pks1",
      //     "pks2",
      //     "pks3",
      //     "pkeNew",
      //     "biodiesel1",
      //     "biodiesel2",
      //     // "biodiesel3",
      //   ],
      // },
    ],
  },
  {
    navLinkName: "Oleochemicals",
    description:
      "Consumers and brands are increasingly embracing sustainable, natural and eco-friendly alternatives to oleochemicals in everyday products such as personal care items, laundry detergent, home care products and cosmetics. Due to our fully integrated value chain from plantation to manufacturing of finished products, we are an integral supplier of oleochemicals derived from palm oil. As the leading supplier of fatty acids, refined glycerin, and soap noodles, we can provide you with technical and application advice, problem solving, and even product customisation.",
    productCategory: "Oleochemicals",
    route: "oleochemicals",
    picture_url: "/images/oleo1ss.jpg",
    subCategory: [
      // {
      //   name: "OLEOCHEMICALS",
      //   description:
      //     "Consumers and brands are increasingly embracing sustainable, natural and eco-friendly alternatives to oleochemicals in everyday products such as personal care items, laundry detergent, home care products and cosmetics. Due to our fully integrated value chain from plantation to manufacturing of finished products, we are an integral supplier of oleochemicals derived from palm oil. As the leading supplier of fatty acids, refined glycerin, and soap noodles, we can provide you with technical and application advice, problem solving, and even product customisation.",
      //   products: [],
      //   pictures: [
      //     // "fattyacidNew", "fattyacidNew2", "fattyacidNew3"
      //   ],
      // },
      {
        name: "Fatty acid",
        description: [
          "There is a wide range of fatty acids available from our company for various applications, including personal care products, cosmetics, home care, and pharmaceuticals.",
        ],
        products: [
          { name: "Split Palm Oil Fatty Acid" },
          { name: "Split Palm Stearin Fatty Acid" },
          { name: "Split Palm Kernel Fatty Acid" },
          { name: "Split Coconut Fatty Acid" },
          { name: "Distilled Palm Oil Fatty Acid" },
          { name: "Distilled Palm Stearin Fatty Acid" },
          { name: "Distilled Palm Kernel Fatty Acid" },
          { name: "Distilled Coconut Fatty Acid" },
          { name: "Caprylic Capric Acid" },
          { name: "Lauric Acid" },
          { name: "Myristirc Acid" },
          { name: "Palmitic Acid" },
          { name: "Stearic Acid" },
          { name: "Oleic Acid" },
        ],
        pictures: [
          // "fattyacidNew", "fattyacidNew2", "fattyacidNew3"
        ],
      },
      {
        name: "Soap Noodles",
        products: [
          { name: "SN 8020" },
          { name: "SN 9010" },
          { name: "MSN 671" },
          { name: "MSN 721" },
        ],
        description: [
          "A variety of specialty applications are made with our 100% vegetable origin soap noodles, including soap bars, cosmetics, laundry soaps, industrial soaps, and other special applications.",
        ],
        pictures: [
          // "soapnoodleNew", "soapnoodleNew2", "soapnoodleNew3"
        ],
      },
      {
        name: "Glycerin",
        description: [
          "We offer refined glycerine that is commonly used across a wide range of industries as a multi-functional structural compound. This product is known for its ability to retain moisture.",
        ],
        products: [
          { name: "Crude Glycerin" },
          { name: "Refined Glycerin USP 995" },
          { name: "Refined Glycerin USP 997" },
        ],
        pictures: [
          // "glycerinNew", "glycerinNew2", "glycerinNew3"
        ],
      },
    ],
  },

  {
    navLinkName: "Fertilizer",
    productCategory: "Fertilizer",
    route: "fertilizer",
    picture_url: "/images/fertilizerss.jpg",
    subCategory: [
      {
        name: "Fertilizer",
        description: [
          "Foods for the crops, fertilizer forms the critical part of our agriculture vertical integration. Dealing with reputable producers globally to bring quality fertilizers into the food chain",
        ],
        products: [],
        pictures: ["fertilizer1ss", "fertilizer2ss", "fertilizer3ss"],
      },
    ],
  },
  {
    navLinkName: "Cement Products",
    productCategory: "Cement",
    picture_url: "/images/cementss.jpg",
    route: "cement",
    subCategory: [
      {
        name: "Clinker",
        description: [
          "Cement clinker is a solid material produced in the manufacture of Portland Cement as an intermediary product. Clinker occurs as lumps or nodules, produced by sintering limestone and aluminosilicate materials such as clay during the cement kiln stage. Portland cement clinker is generally ground to a fine powder and used as the binder in many cement products. A small amount of gypsum is added and or other active ingredients such as: Fly Ash, Slag (GGBFS), Pozzolana to achieve various types of cement.",
          "We export clinker in bulk through our dedicated sources in Indonesia and Vietnam which guarantees a minimum loading rate of 10,000 MT and 8,000 MT respectively per day. In Indonesia the Bayah Port can accommodate up to 50,000 DWT vessels and in Vietnam we can work up to 80,000 DWT vessels at Campha Anchorage.",
        ],
        products: [],
        pictures: [
          "cemindoplant32",
          "clinker2ss",
          // "clinker1",
          "clinker1ss32",
          "clinker432",
          "clinker532",
          "clinker632",
        ],
      },
      {
        name: "Cement",
        description: [
          "A Cement is a binder, a substance used for construction that sets, hardens, and adheres to other materials to bind them together. Cement is seldom used on its own, but rather to bind sand and gravel together. Cement when mixed with aggregate form mortar for masonry, or Concrete when mixed with sand and gravel. Cement is classified as either Hydraulic or Non-Hydraulic depending on the ability of the cement to set in the presence of water.",
          "We export both bulk and bagged Cement through our dedicated sources in Indonesia and Vietnam. For bagged cement we can either use our Producerer's artwork or a buyers' customised design.",
          "Loading rates vary based on bulk or bagged shipment and the stem sizes.",
        ],
        products: [],
        pictures: [
          "chinfonplant332",
          // "cement4",
          "cement2ss",
          // "cementNew7",
          // "cementNew9",
          "cement3ss32",
          "Cement432",
          "Cement532",
          "Cement632",
          "cement732",
          // "cementNew6",
          "cement832",
          // "cementNew5",
          "cement932",
          // "cementNew14",
        ],
      },
    ],
  },
  // {
  //   navLinkName: "Shipping",
  //   productCategory: "Shipping",
  //   route: "shipping",
  //   picture_url: "/images/shipping.jpg",
  //   subCategory: [
  //     { name: "Dry Cargo", products: [], pictures: ["drycargo1", "drycargo2"] },
  //     { name: "Wet Cargo", products: [], pictures: ["wetcargo1", "wetcargo2"] },
  //     {
  //       name: "Container",
  //       products: [],
  //       pictures: ["container1", "container2", "container3"],
  //     },
  //   ],
  // },
];

export const productSpecificationsData = [
  // {
  //   name: "CPO",
  //   details: [
  //     {
  //       product: "Local Malaysian Delivered CPO (PORAM / MPOA)",
  //       quality_guide_line: [
  //         "FFA 5.00% max",
  //         `M&I 0.25% max
  //         (>0.25% to 0.5% adjustment 1:1 on load basis)
  //         (>0.5% rejectable on load basis)`,
  //         `DOBI 2.3min
  //         (<2.3 to 2.1, RM3.00pmt discount on load basis)
  //         (<2.1, rejectable on load basis)`,
  //       ],
  //     },
  //     {
  //       product: "Indonesian CPO",
  //       quality_guide_line: ["FFA 5.00% max & M&I 0.50% max"],
  //     },
  //     {
  //       product: "Futures CPO",
  //       quality_guide_line: [
  //         "CPO of good merchantable quality, in bulk & unbleached",
  //         "FFA 4% max when delivered into Port Tank Installations",
  //         "FFA 5% max when delivered from Port Tank Installations",
  //         "M & I 0.25% max",
  //         "DOBI 2.50 min when delivered into Port Tank Installations",
  //         "DOBI 2.31 min when delivered from Port Tank Installations",
  //       ],
  //     },
  //   ],
  // },
  {
    name: "PORAM",
    details: [
      {
        product: "RBD Palm Olein",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "5.00% max",
          "0.5% max",
          "56 min",
          "24 Deg C max",
          "3 Red max",
        ],
      },
      {
        product: "Crude Palm Oil",
        item: ["FFA (as palmitic)", "M & I"],
        quality_guide_line: ["5.00% max", "0.50% max"],
      },
      {
        product: "RBD Palm Oil",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.10% max",
          "0.10% max",
          "50 - 55",
          "33 - 39 Deg C ",
          "3 Red max",
        ],
      },
      {
        product: "RBD Palm Stearin",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.20% max",
          "0.15% max",
          "48 max",
          "44 Deg C min",
          "3 Red max",
        ],
      },
      {
        product: "Palm Fatty Acid Distillate",
        item: ["Saponifiable Fatty Matter", "M & I", "FFA (as palmitic)"],
        quality_guide_line: ["95% min. (basis 97%)", "1.00% max", "70% min"],
      },
      {
        product: "Crude Palm Olein",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
        ],
        quality_guide_line: ["5% max", "0.25% max", "56 min", "24 Deg C max"],
      },
      {
        product: "Crude Palm Stearin",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
        ],
        quality_guide_line: ["5% max", "0.25% max", "48 max", "44 Deg C min"],
      },
    ],
  },

  {
    name: "MEOMA",
    details: [
      {
        product: "RBD Palm Kernel Oil",
        item: [
          "FFA (as lauric acid)",
          "M & I",
          "Iodine Value (Wijs)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.10% max",
          "0.10% max",
          "19 max at time of shipment",
          "Red 1.5 max",
        ],
      },
      // {
      //   product: "RBD Palm Kernel Olein",
      //   item: [
      //     "FFA (as lauric acid)",
      //     "M & I",
      //     "Iodine Value (Wijs)",
      //     "Colour (5.25″ Lovibond cell)",
      //   ],
      //   quality_guide_line: ["0.10% max", "0.10% max", "21 min", "Red 1.5 max"],
      // },
      // {
      //   product: "RBD Palm Kernel Stearin",
      //   item: [
      //     "FFA (as lauric acid)",
      //     "M & I",
      //     "Iodine Value (Wijs)",
      //     "Colour (5.25″ Lovibond cell)",
      //   ],
      //   quality_guide_line: ["0.10% max", "0.10% max", "8 max", "Red 1.5 max"],
      // },
      {
        product: "Crude Palm Kernel Oil",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: [
          "5.00% max",
          "0.50% max",
          "19 max at time of shipment",
        ],
      },
      {
        product: "Crude Palm Kernel Olein",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: ["5.00% max", "0.50% max", "21 min"],
      },
      {
        product: "Crude Palm Kernel Stearin",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: ["5.00% max", "0.50% max", "8 max"],
      },
      {
        product: "Palm Kernel Fatty Acid Distillate",
        item: ["FFA (as lauric acid)", "M & I", "TFM"],
        quality_guide_line: ["50% min", "1.00% max", "95% min"],
      },
      {
        product: "RBD Coconut Oil",
        item: [
          "FFA (as lauric acid)",
          "M & I",
          "Iodine Value (Wijs)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.10% max",
          "0.10% max",
          "7.5 - 10.5",
          "Red 1.5 max",
        ],
      },
      {
        product: "Crude Coconut Oil (Grade 1)",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: ["1.00% max", "0.50% max", "7.5 - 10.5"],
      },
    ],
  },
];

export const productDetailsData = [
  {
    title: "RBD PALM OLEIN",
    description:
      "Refined, Bleached & Deodorised Palm Olein (RBD Palm Olein) is the liquid fraction obtained from fractionation of palm oil. RBD Palm Olein is fully liquid at ambient temperature in warm climates. It can blend with various vegetable oils in different proportions to obtain liquid oils which can withstand lower temperatures. It is used as cooking oil for household and in food manufacturing such as fried food products. Our brands White Lily, WOK, Marigold and Aster are popular in several destinations around the world.",
    pictures: [
      "white-lily",
      "wok-brand",
      "marigold",
      "aster-brand-purple",
      "oleinNew2",
      "wokconsumer",
      "packedOlein1ss43",
      "packedOlein2ss43",
    ],
    parent_route: "packed",
    product_specification: [
      {
        IV: "56 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "10°C max",
      },
      {
        IV: "57 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "8°C max",
      },
      {
        IV: "58 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "7°C max",
      },
      {
        IV: "60 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
      {
        IV: "62 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
      {
        IV: "64 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
      {
        IV: "66 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
    ],
  },
  {
    title: "RBD PALM OIL",
    description:
      "Refined, Bleached & Deodorised Palm Oil (RBD Palm Oil) is obtained from refining Crude Palm Oil (CPO). It is bland, odorless, light yellow in colour and semi-solid in room temperature, making it ideal for use as deep frying oil for food industries such as snack products and baked goods. It is also used as a raw material to produce shortening, margarine, vegetable ghee, condensed milk and soap.",
    pictures: [
      "packedPalm1ss43",
      "packedPalm2ss43",
      "packedPalm3ss43",
      "packedPalm4ss43",
    ],
    parent_route: "packed",
    product_specification: [
      {
        IV: "50-55",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        SMP: "30-39°C max",
      },
    ],
  },
  {
    title: "RBD PALM STEARIN",
    description:
      "Refined, Bleached & Deodorised Palm Stearin (RBD Palm Stearin) is the solid fraction from the fractionation of palm oil. It can blend with other vegetable oils to obtain suitable functional products such as margarine fats, shortenings, vegetable ghee etc. It is also a useful natural hard stock for making trans-free fats and used as raw material in feed, soap, candles and oleochemical industries.",
    pictures: ["stearin1ss43", "stearin2ss43", "stearin3ss", "stearin4ss43"],
    parent_route: "packed",
    product_specification: [
      {
        IV: "48 max",
        FFA: "0.2% max",
        MNI: "0.15% max",
        CL: "3.0R max",
      },
    ],
  },

  {
    title: "VEGETABLE GHEE",
    description:
      "Vegetable Ghee also known as Vanaspati, is made up almost entirely of hardened vegetable oil and is free from any milk products. It is a vegetarian alternative to this product. It is ideal for various forms of cooking, baking and frying.",
    pictures: ["ghee1ss43", "ghee2ss43", "ghee3ss43", "ghee4ss43"],
    parent_route: "packed",
    product_specification: [
      { SMP: "36-39°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "40-42°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
    ],
  },
  {
    title: "SHORTENING",
    description:
      "Shortening is a type of solid fat made from palm oil with some of the unsaturated fats removed so that it is even more stable and has a thick, luxurious texture that is pefect for baking. It is widely used for making pastry and cooking.",
    pictures: [
      "shortening1ss43",
      "shortening2ss",
      "shortening3ss43",
      "shortening4ss43",
    ],
    parent_route: "packed",
    product_specification: [
      { SMP: "34-38°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "36-39°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "38-41°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "41-43°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "42-44°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "44-48°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "48-50°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "48-52°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "50-52°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
    ],
  },

  {
    title: "FRYING FAT",
    description:
      "Frying Fat is made from palm oil. It is highly heat stable and naturally resistant to oxidation. It is specially designed for all purpose deep frying and excellent for multiple frying application in snack food industries and fast-food outlets.",
    pictures: [
      "fryingFat1ss43",
      "fryingFat2ss43",
      "fryingFat3ss43",
      "fryingFat4ss43",
    ],
    parent_route: "packed",
    product_specification: [
      {
        P: "Super Fry",
        SMP: "39°C max",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "5.0R max",
      },
      {
        P: "Xtra Fry",
        SMP: "42°C max",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "5.0R max",
      },
    ],
  },
  {
    title: "BUTTER OIL SUBSTITUTE",
    description:
      "Butter Oil Substitute (BOS) is a vegetarian alternative to butter oil. It is widely used in the bakery industry to replace the dairy-based butter oil. It is similar to shortening. However, BOS have strong butter flavour and deep yellow colour.",
    pictures: [
      "butteroilsubNew3",
      "butteroilsubNew2",
      "butteroilsub3",
      "butteroilsubNew4",
    ],
    parent_route: "packed",
    product_specification: [
      { SMP: "36-39°C", FFA: "0.1% max", MNI: "0.1% max", CL: "7.0R max" },
    ],
  },
  {
    title: "MILK FAT REPLACER",
    description:
      "Milk Fat Replacer is a substitute for milk fat. It is specially formulated vegetable fat blends that can replace milk fat in various dairy products. It is widely used for making cheese, whipping cream and spread.",
    pictures: ["milkfatNew5", "milkfatNew6", "milkfatNew4", "milkfatNew7"],
    parent_route: "packed",
    product_specification: [
      { SMP: "30-35°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
    ],
  },
  {
    title: "SUGAR",
    description:
      "Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose. Compound sugars, also called disaccharides or double sugars, are molecules made of two bonded monosaccharides; common examples are sucrose (glucose + fructose), lactose (glucose + galactose), and maltose (two molecules of glucose). White sugar is a refined form of sucrose. In the body, compound sugars are hydrolysed into simple sugars.",
    pictures: ["sugar1ss43", "sugar2ss43", "sugar3ss43", "sugar4ss43"],
    parent_route: "packed",
    product_specification: [],
  },
  {
    title: "RICE",
    description:
      "Rice is a staple food in many cultures around the world and has been a part of the human diet for thousands of years. Rice is a versatile grain that can be used in a variety of dishes, and it is a good source of vitamins and minerals. Our organisation offer a wide variety of rice products to choose from. We have rice products for all occasions, from everyday meals to special occasions. We also have a variety of rice products for different dietary needs, including gluten-free and vegan options. We are committed to providing our customers with the highest quality rice products available. We source our rice from the best growers and processors in the world, and we only sell products that we would personally eat ourselves.",
    pictures: ["rice1ss43", "rice2ss43", "rice3ss43", "rice4ss43"],
    parent_route: "packed",
    product_specification: [],
  },
  // {
  //   title: "RICE",
  //   description:
  //     "Milk Fat Replacer is a substitute for milk fat. It is specially formulated vegetable fat blends that can replace milk fat in various dairy products. It is widely used for making cheese, whipping cream and spread.",
  //   pictures: [],
  //   parent_route: "packed",
  //   product_specification: [],
  // },
  {
    title: "Split Palm Oil Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Split Palm Stearin Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Split Palm Kernel Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Split Coconut Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Palm Oil Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Palm Stearin Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Palm Kernel Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Coconut Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Caprylic Capric Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Lauric Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Myristirc Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Palmitic Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Stearic Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Oleic Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Hydrogenated Palm Oil".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Hydrogenated Palm Stearin".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Toiletries Soap Noodles".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Laundry Soap Noodles".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Multipurpose Soap Noodles".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Crude Glycerin".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Refined Glycerin".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
];
