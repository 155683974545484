import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import { productDetailsData } from "../data/productData";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import SpecAccordion from "../components/SpecAccordion";

const IndividualProducts = () => {
  const [product, setProduct] = useState({
    title: "",
    description: "",
    picture_url: "",
    parent_route: "",
    product_specification: [{ header: [], data: [] }],
  });
  const [columns, setColumns] = useState([]);
  const { individualProduct } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getProduct = (name) => {
    const linkName = name.replaceAll("-", " ").toUpperCase();
    return productDetailsData?.filter(
      (productDetail) => productDetail?.title === linkName
    )[0];
  };

  const setColumnsForProduct = (productTitle) => {
    if (
      productTitle === "MILK FAT REPLACER" ||
      productTitle === "VEGETABLE GHEE" ||
      productTitle === "SHORTENING" ||
      productTitle === "BUTTER OIL SUBSTITUTE"
    ) {
      setColumns([
        {
          name: "Slip Melting Point (SMP)",
          selector: (row) => row.SMP,
        },
        {
          name: "Free Fatty Acid as Palmitic (FFA)",
          selector: (row) => row.FFA,
        },
        {
          name: "Moisture & Impurities (M&I)",
          selector: (row) => row.MNI,
        },
        {
          name: 'Colour Lovibond 5.25"',
          selector: (row) => row.CL,
        },
      ]);
    } else if (productTitle === "RBD PALM OLEIN") {
      setColumns([
        {
          name: "Iodine Value (IV)",
          selector: (row) => row.IV,
        },
        {
          name: "Free Fatty Acid as Palmitic (FFA)",
          selector: (row) => row.FFA,
        },
        {
          name: "Moisture & Impurities (M&I)",
          selector: (row) => row.MNI,
        },
        {
          name: 'Colour Lovibond 5.25"',
          selector: (row) => row.CL,
        },
        {
          name: "Cloud Point (CP)",
          selector: (row) => row.CP,
        },
      ]);
    } else if (productTitle === "RBD PALM OIL") {
      setColumns([
        {
          name: "Iodine Value (IV)",
          selector: (row) => row.IV,
        },
        {
          name: "Free Fatty Acid as Palmitic (FFA)",
          selector: (row) => row.FFA,
        },
        {
          name: "Moisture & Impurities (M&I)",
          selector: (row) => row.MNI,
        },
        {
          name: 'Colour Lovibond 5.25"',
          selector: (row) => row.CL,
        },
        {
          name: "Slip Melting Point (SMP)",
          selector: (row) => row.SMP,
        },
      ]);
    } else if (productTitle === "RBD PALM STEARIN") {
      setColumns([
        {
          name: "Iodine Value (IV)",
          selector: (row) => row.IV,
        },
        {
          name: "Free Fatty Acid as Palmitic (FFA)",
          selector: (row) => row.FFA,
        },
        {
          name: "Moisture & Impurities (M&I)",
          selector: (row) => row.MNI,
        },
        {
          name: 'Colour Lovibond 5.25"',
          selector: (row) => row.CL,
        },
      ]);
    } else if (productTitle === "FRYING FAT") {
      setColumns([
        {
          name: "Product",
          selector: (row) => row.P,
        },
        {
          name: "Slip Melting Point (SMP)",
          selector: (row) => row.SMP,
        },
        {
          name: "Free Fatty Acid as Palmitic (FFA)",
          selector: (row) => row.FFA,
        },
        {
          name: "Moisture & Impurities (M&I)",
          selector: (row) => row.MNI,
        },
        {
          name: 'Colour Lovibond 5.25"',
          selector: (row) => row.CL,
        },
      ]);
    }
  };

  useEffect(() => {
    setProduct(getProduct(individualProduct));
    setColumnsForProduct(product?.title);
  }, [individualProduct, product?.title]);

  return (
    <>
      {/* <section
        style={{
          background: "url(https://picsum.photos/1920/1080?random=1)",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "50vh",
          position: "relative",
        }}
      > */}
      {/* </section> */}
      <section className="d-flex justify-content-center align-items-center flex-column">
        <div className="d-flex w-100">
          <button
            className="btn btn-outline-danger rounded-0 border-0 top-0 start-0 "
            onClick={() => navigate(`/products/${product.parent_route}`)}
          >
            Back to {product.parent_route} products
          </button>
        </div>
        {/* <Card.ImgOverlay
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            minHeight: "100",
            padding: "0",
          }}
        > */}
        <Container className="p-5">
          <Typography variant="h4" className="p-2 text-danger">
            {product.title}
          </Typography>
          <Typography variant="body2" align="left">
            {product.description}
          </Typography>
          <Box>
            <Row className="mt-3">
              {product.pictures?.map((picture, index) => (
                <Col
                  key={index}
                  lg={3}
                  className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
                >
                  <Card
                    className="border-0 rounded-0"
                    onClick={() => console.log("CLICKED")}
                    style={{ overflow: "hidden" }}
                  >
                    <Card.Img
                      variant="top"
                      src={`/images/${picture}.jpg`}
                      className="rounded-0 img-zoom product-card-img h-100"
                    />
                    {/* <Card.ImgOverlay
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                      className="d-flex justify-content-center align-items-center rounded-0"
                    >
                      <Card.Title style={{ color: "white" }}>
                        Insert Brand OR Product IMG
                      </Card.Title>
                    </Card.ImgOverlay> */}
                  </Card>
                </Col>
              ))}
              {/* <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col
                lg={3}
                className="p-2 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() => console.log("CLICKED")}
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={`https://picsum.photos/600/400?random=1`}
                    className="rounded-0 img-zoom product-card-img"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      Insert Brand OR Product IMG
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col> */}
            </Row>
          </Box>
          {product.product_specification?.length > 0 && (
            <Box sx={{ marginTop: "32px" }}>
              <SpecAccordion
                columns={columns}
                data={product.product_specification}
              />
            </Box>
          )}
        </Container>
        {/* </Card.ImgOverlay> */}
      </section>
    </>
  );
};

export default IndividualProducts;
