import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="pt-5 mt-2">
      <Row className="mb-4">
        <Typography
          textAlign="left"
          variant="h3"
          sx={{
            fontWeight: "300 !important",
          }}
        >
          About Us
        </Typography>
      </Row>
      {/* <Row className="m-2">
        <div className="col-12 col-md-6 text-end">
          <Typography variant="h3">
            About <span style={{ color: "#ab2f2b" }}>KPN Corp</span>
          </Typography>
          <div></div>
          <p className="text-start">
            KPN Corp consists of four main business divisions, namely :
            agriculture, cement and building materials, property, and
            international trading. Primarily based in Indonesia, we have also
            business presence in Singapore, Vietnam, Sri Lanka, and Mauritius.
            We are committed to providing the best quality products and services
            to our customers, and have more than 30,000 employees that serve the
            world in a responsible way. Together with our staff, customers,
            governments and communities, we strive to minimize environmental
            impact and improve the communities where we live and work.
          </p>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-center">
          <img src="images/kpn-logo.png" className="img-fluid" alt="" />
        </div>
      </Row> */}
      <Row className="my-2 p-1">
        <div className="col-12 col-lg-6 align-items-top">
          <img src="images/aboutss43.jpg" alt="" className="img-fluid" />
        </div>
        <div className="col-12 col-lg-6 text-start align-items-center d-flex flex-column justify-content-center">
          <div className="w-100">
            <Typography variant="h4" sx={{ color: "#ab2f2b" }}>
              Aastar Trading Pte Ltd
            </Typography>
          </div>
          <p>
            Aastar Trading is an international trading company based in
            Singapore. Our business is in the global supply chain management
            specialised in procurement and distribution of cement products,
            fertilizer, oils & fats, consumer products, oleochemicals and green
            energy. We are equipped with strong industry knowledge and multiple
            years of experience. Our sales and distribution network covers
            countries worldwide. With our continuous commitment to providing the
            best value to our suppliers and buyers, we have gained strong
            recognition and unwavering trust from our stakeholders over the
            years. Our motivated team is ever-ready to deliver what it takes to
            ensure all shipments are done in a timely, professional and
            efficient manner. The phenomenal growth of our trading activities in
            terms of scale and value bears testimony to the confidence our trade
            partners have in us.
          </p>
          <Typography sx={{ width: "100%" }} variant="h4" align="left">
            Our Mission
          </Typography>
          <Typography variant="body1" align="left">
            <p>
              To provide the best quality products and services, empowering
              people's lives and communities, whilst ensuring environmental
              sustainability, and in doing so, provide additional value to all
              stakeholders.
            </p>
          </Typography>
          <Typography sx={{ width: "100%" }} variant="h4" align="left">
            Our Commitment
          </Typography>
          <Typography variant="body1" align="left">
            <p>Employees and staff are allowed to associate and gather, express thoughts/opinions verbally and in writing for peaceful purposes as regulated in applicable laws and regulations while maintaining a conducive atmosphere for the continuation of operational activities.</p>
            <li>Support gender equality and prevent gender discrimination in employment practices, training opportunities, employment contracts, engagement processes and management activities.</li>
            <li>Providing employment opportunities with equal terms and working conditions open to men, women and people with disabilities and all are encouraged to participate actively in all levels of work.</li>
            <li>Women and men are paid the same wages for the same work.</li>
            <li>Providing equal employment opportunities and opportunities to anyone who wants to work in the company regardless of religion or belief, race, ethnicity, gender in accordance with the mechanism established by the Company.</li>
            <li>Every work has the same rights and obligations regardless of religion or belief, race, ethnicity, gender in accordance with the mechanism established by the Company.</li>
            <li>Minimum age when accepted as a workforce is not less than 18 (eighteen) years.</li>
            <li>Does not provide space and opportunity to employ children under 18 years of age.</li>
            <li>This policy applies to all workers and all Aastar Trading Pte Ltd Partners.</li>
          </Typography>
        </div>
      </Row>
      {/* <Row className="mt-3 p-1">
        <Col className="p-3" lg="6" sm="12">
          <Typography variant="h4" align="left">
            Vision
          </Typography>
          <Typography variant="body1" align="left">
            To prosper together with our stakeholders, in a responsible and
            sustainable way.
          </Typography>
        </Col>
        <Col className="p-3" lg="6" sm="12">
          <Typography variant="h4" align="left">
            Mission
          </Typography>
          <Typography variant="body1" align="left">
            To provide the best quality products and services, empowering
            people's lives and communities, whilst ensuring environmental
            sustainability, and in doing so, provide additional value to all
            stakeholders.
          </Typography>
        </Col>
      </Row> */}

      {/* <div className="row m-3">
        <div className="col-3">
          <img
            height="200"
            src="images/cemindo-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-3">
          <img
            height="200"
            src="images/gama-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-3">
          <img
            height="200"
            src="images/baru-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-3">
          <img
            height="200"
            src="images/baruplant-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
      </div> */}
    </Container>
  );
};

export default About;
