import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataTable from "react-data-table-component";
import React from "react";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "500",
      textTransform: "uppercase",
      color: "white",
      backgroundColor: "#ae2c2b",
    },
  },
};

const SpecAccordion = ({ columns, data }) => {
  return (
    <Accordion
      sx={{
        color: "#ae2c2b",
        border: "1px solid #ae2c2b",
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#ae2c2b" }} />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>View Specifications</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataTable columns={columns} data={data} customStyles={customStyles} />
      </AccordionDetails>
    </Accordion>
  );
};

export default SpecAccordion;
