import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Product from './pages/Product';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import IndividualProducts from './pages/IndividualProducts';
import { Box } from '@mui/material';

function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '85vh',
          }}
        >
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/products/:productRoute' element={<Product />} />
            <Route
              path='/products/:productRoute/:subCategory/:individualProduct'
              element={<IndividualProducts />}
            />
          </Routes>
        </Box>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
