import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import DataTable from "react-data-table-component";
import Map from "../components/Map";

const location = {
  address: "3 Church St",
  lat: 1.2834376696770975,
  lng: 103.84968115408694,
};

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "500",
      textTransform: "uppercase",
      color: "white",
      backgroundColor: "#ae2c2b",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
    },
  },
};

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const columns = [
    {
      name: "Enquiry",
      selector: (row) => row.enquiry,
    },
    {
      name: "Contact",
      selector: (row) => (
        <button
          className="btn btn-outline-danger border-0 border-none"
          onClick={() => {
            window.location = `mailto:${row.email}`;
          }}
          id={row.ID}
        >
          {row.email}
        </button>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      enquiry: "General Enquiries",
      email: "enquiries@kpn-corp.com",
    },
    {
      id: 2,
      enquiry: "Oils & Fats",
      email: "oilsandfats@kpn-corp.com",
    },
    {
      id: 3,
      enquiry: "Packed Products",
      email: "packed@kpn-corp.com",
    },
    {
      id: 4,
      enquiry: "Biofuel",
      email: "biofuel@kpn-corp.com",
    },
    {
      id: 5,
      enquiry: "Oleochemicals",
      email: "oleo@kpn-corp.com",
    },
    {
      id: 6,
      enquiry: "Fertilizer",
      email: "fertilizer@kpn-corp.com",
    },
    {
      id: 7,
      enquiry: "Cement Products",
      email: "cement@kpn-corp.com",
    },
  ];

  return (
    <Container className="pt-5">
      <div className="row mb-4 mt-3 p-2">
        <Typography
          textAlign="left"
          sx={{ fontWeight: "300 !important" }}
          variant="h3"
        >
          Contact Us
        </Typography>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-12 d-flex justify-content-center flex-column p-2">
          <h5>Aastar Trading Pte Ltd</h5>
          <div>
            <p className="mb-0">Samsung Hub</p>
            <p className="mb-0">3 Church St #13-02</p>
            <p className="mb-0">Singapore 049483</p>
            <p className="mb-0">Tel: +65 6571 6500</p>
            <p className="mb-0">Fax: +65 6224 6625</p>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12 p-2 h-100">
          <Map location={location} zoomLevel={17}></Map>
        </div>
      </div>
      <div className="mt-5">
        <DataTable columns={columns} data={data} customStyles={customStyles} />
      </div>
    </Container>
  );
};

export default Contact;
