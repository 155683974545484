import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import PLSpecAccordion from "../components/PLSpecAccordion";
import { productData, productSpecificationsData } from "../data/productData";

const Product = () => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedSpecification, setSelectedSpecification] = useState({});
  const [allProductSpecifications, setAllProductSpecifications] = useState([]);
  const { productRoute } = useParams();
  const navigate = useNavigate();
  const layoutOneList = ["Packed Products"];
  const layoutTwoList = [
    "Biofuel",
    "Oil and fats",
    "Fertilizer",
    "Cement",
    "Shipping",
    "Oleochemicals",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const category = productData.filter(
      (product) => product.route === productRoute
    )[0];
    setSelectedCategory(category);
    if (Object.keys(selectedCategory).length !== 0) {
      setSelectedSubCategory(selectedCategory?.subCategory[0]);
    }
    setAllProductSpecifications(productSpecificationsData);
    setSelectedSpecification(productSpecificationsData[0]);
  }, [productRoute, selectedCategory]);

  const getSubcategory = (name) =>
    selectedCategory.subCategory.filter(
      (subCategory) => subCategory.name === name
    )[0];

  if (
    selectedCategory &&
    layoutOneList.includes(selectedCategory.productCategory)
  ) {
    return (
      <Container className="d-flex justify-content-center align-items-center flex-column">
        <Row className="d-flex justify-content-center w-50 pt-5 mt-2">
          <h2 className="mb-1 text-capitalize">
            {selectedCategory.productCategory}
          </h2>
          <div
            className="border-bottom border-danger border-3"
            style={{ width: "20%" }}
          ></div>
        </Row>
        <Row className="mt-4 align-items-center">
          {selectedCategory.subCategory?.map((sub, index) => (
            <Col key={index}>
              {/* <Button
                variant={
                  selectedSubCategory.name === sub.name
                    ? "danger"
                    : "outline-danger"
                }
                className="rounded-0"
                onClick={() => {
                  setSelectedSubCategory(sub);
                }}
              >
                {sub.name}
              </Button> */}
            </Col>
          ))}
        </Row>
        <Row className="mt-3">
          {selectedSubCategory &&
            selectedSubCategory.products?.map((product, index) => (
              <Col
                key={index}
                lg={3}
                className="p-1 d-flex justify-content-center rounded-0 cursor-pointer"
              >
                <Card
                  className="border-0 rounded-0"
                  onClick={() =>
                    navigate(
                      `/products/${selectedCategory.navLinkName
                        .replaceAll(" ", "-")
                        .toLowerCase()}/${selectedSubCategory.name
                        .replaceAll(" ", "-")
                        .toLowerCase()}/${product.name
                        .replaceAll(" ", "-")
                        .toLowerCase()}`
                    )
                  }
                  style={{ overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={
                      product.picture_url?.length > 0
                        ? product.picture_url
                        : `https://picsum.photos/300/200?random=${index}`
                    }
                    className="rounded-0 img-zoom product-card-img w-100 h-100"
                  />
                  <Card.ImgOverlay
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    className="d-flex justify-content-center align-items-center rounded-0"
                  >
                    <Card.Title style={{ color: "white" }}>
                      {product.name.toUpperCase()}
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    );
  } else if (
    selectedCategory &&
    layoutTwoList.includes(selectedCategory.productCategory)
  )
    return (
      <>
        {/* <Container className="mt-5 pt-5 pb-5">
          <Row className="d-flex align-items-center justify-content-center w-50 mx-auto">
            <h2 className="mb-1">{selectedCategory.productCategory}</h2>
            <div
              className="border-bottom border-danger border-3"
              style={{ width: "20%" }}
            ></div>
          </Row>
        </Container> */}
        <section
          className="d-flex justify-content-center align-items-center product-hero"
          style={{
            backgroundImage:
              selectedCategory.picture_url?.length > 0
                ? `url(${selectedCategory.picture_url})`
                : "url(/images/image-4.jpg)",
            backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
            height: "75vh",
          }}
        >
          <Container className="d-flex justify-content-start align-items-center">
            <div
              className="p-3"
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                color: "white",
                marginTop: "60px",
                boxShadow: "4px 6px 7px -4px #000000",
              }}
            >
              <h1 className="text-start">{selectedCategory.navLinkName}</h1>
              {/* {selectedCategory.description && (
                <div className="container px-0 py-3">
                  <h5 className="text-start mb-0">
                    {selectedCategory.description}
                  </h5>
                </div>
              )} */}

              {/* <p className="text-start">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut
                officiis provident officia explicabo voluptate voluptatibus
                ratione laudantium pariatur. Natus, vel! Repellendus expedita
                maiores optio! Cupiditate maiores distinctio illum! Itaque, eos.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam
                nostrum voluptas dolor, dolorem tempore quo, dignissimos
                suscipit, reiciendis optio at eum voluptatibus exercitationem
                omnis nihil? Laudantium id doloribus quos vitae?
              </p> */}
            </div>
          </Container>
        </section>
        <section>
          <Container>
            {selectedCategory.description && (
              <div className="container  p-3 mb-2">
                <h5 className="text-start mb-0">
                  {selectedCategory.description}
                </h5>
              </div>
            )}
            {selectedCategory.subCategory?.length > 1 && (
              <>
                <Row className="justify-content-center">
                  {selectedCategory.subCategory?.map((subCategory, index) => (
                    <Col className="p-0" xs={12} md={3} key={index}>
                      <button
                        className={
                          (subCategory.name === selectedSubCategory.name
                            ? "btn-danger "
                            : "btn-outline-danger ") +
                          "btn-lg rounded-0 w-100 border-0 h-100"
                        }
                        onClick={() =>
                          setSelectedSubCategory(
                            getSubcategory(subCategory.name)
                          )
                        }
                      >
                        {subCategory.name}
                      </button>
                    </Col>
                  ))}
                </Row>
                <Row>
                  {/* {selectedCategory.description && (
                    <div className="container px-0 py-3">
                      <h5 className="text-start mb-0">
                        {selectedCategory.description}
                      </h5>
                    </div>
                  )} */}
                </Row>
              </>
            )}
            <section className="p-3 mt-3">
              <h3>{selectedSubCategory.name}</h3>
              {selectedSubCategory.description &&
                selectedSubCategory.description.map((des, index) => (
                  <h5 className="text-start" key={index}>
                    {des}
                  </h5>
                ))}
              <div className="d-flex justify-content-center">
                <div className="row d-flex justify-content-center gap-2">
                  {selectedSubCategory?.products?.map((item, index) => (
                    <div
                      key={index}
                      className="card col-sm-5 col-lg-5 border-danger p-3 justify-content-center"
                    >
                      {item.name.toUpperCase()}
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {selectedSubCategory.pictures?.length > 0 && (
              <section>
                <Row>
                  {selectedSubCategory.pictures?.map((picture, index) => (
                    <Col
                      key={index}
                      lg={4}
                      className="p-1 d-flex justify-content-center rounded-0"
                    >
                      <Card
                        className="border-0 rounded-0 h-100"
                        style={{ overflow: "hidden" }}
                      >
                        <Card.Img
                          variant="top"
                          src={`/images/${picture}.jpg`}
                          className="rounded-0 img-zoom product-card-img w-100 h-100"
                        />
                        {/* <Card.ImgOverlay
                          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                          className="d-flex justify-content-center align-items-center rounded-0"
                        >
                          <Card.Title style={{ color: "white" }}>
                            {picture}
                          </Card.Title>
                        </Card.ImgOverlay> */}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </section>
            )}
            {selectedSubCategory.videos?.length > 0 && (
              <section className="mt-1">
                <h4 className="text-start mb-0">Videos</h4>
                <Row>
                  {selectedSubCategory.videos?.map((video, index) => (
                    <div
                      key={index}
                      className="p-1 d-flex justify-content-center rounded-0 col-lg-4"
                    >
                      <div
                        className="card border-0"
                        style={{ background: "#F8F9FA" }}
                      >
                        <video muted controls poster={video.poster}>
                          <source src={video.src} type="video/mp4" />
                        </video>
                        <div
                          style={{ background: "#F8F9FA" }}
                          className="card-body px-0 py-1"
                        >
                          <p className="card-text fw-light text-start">
                            {video.desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              </section>
            )}
          </Container>
          {/* {selectedSubCategory?.name === 'Palm & Lauric' && (
            <Container className='px-0'>
              <PLSpecAccordion
                selectedSubCategory={selectedSubCategory}
                setSelectedSpecification={setSelectedSpecification}
                selectedSpecification={selectedSpecification}
                allProductSpecifications={allProductSpecifications}
              />
            </Container>
          )} */}
        </section>
      </>
    );
};

export default Product;
